var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var PROJECT_DETAILS_SUMMARY_QUERY = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  query projectSummaryDetails(\n    $projectId: String!\n    $organisationId: String!\n    $teamId: String!\n    $startDate: String!\n    $endDate: String!\n    $timezone: String!\n    $role: String!\n    $userUrl: String!\n  ) {\n    projectSummaryDetails(\n      projectId: $projectId\n      organisationId: $organisationId\n      teamId: $teamId\n      startDate: $startDate\n      endDate: $endDate\n      timezone: $timezone\n      role: $role\n      userUrl: $userUrl\n    ) {\n      projectDetailsSummary {\n        projectName\n        totalWorkedSec\n        estimatedWorkedSec\n        estimatedTime\n        # totalTaskCount\n        # completedTaskCount\n        # dueTaskCount\n        # ongoingTaskCount\n        projectType\n        budget\n        totalBudject\n        userTotalWorkedHours\n      }\n      projectTeamIds\n      projectCount\n      projectSelectedHeadList\n    }\n  }\n"])));
export var UPDATE_PROJECT_ESTIMATED_BUDGET_MUTATION = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation updateProjectEstimatedBudget($projectId: String!, $organisationId: String!, $projectBudget: Int!) {\n    updateProjectEstimatedBudget(projectId: $projectId, organisationId: $organisationId, projectBudget: $projectBudget)\n  }\n"])));
export var PROJECT_DETAILS_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query ProjectDetails(\n    $projectId: String!\n    $organisationId: String!\n    $teamId: String!\n    $startDate: String!\n    $endDate: String!\n    $timezone: String!\n  ) {\n    projectDetails(\n      projectId: $projectId\n      organisationId: $organisationId\n      teamId: $teamId\n      startDate: $startDate\n      endDate: $endDate\n      timezone: $timezone\n    ) {\n      projectWorkDetails {\n        day\n        users {\n          userId\n          displayName\n          profileUrl\n          workedSec\n        }\n      }\n      userWorkDetails {\n        displayName\n        workedSec\n        userId\n        profileUrl\n        uniqueUrl\n      }\n      projectTeamIds\n    }\n  }\n"])));
export var PROJECT_TASKS_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query ProjectTasks(\n    $projectId: String!\n    $organisationId: String!\n    $teamId: String!\n    $startDate: String!\n    $endDate: String!\n    $taskStatus: String\n    $search: String\n    $limit: Int!\n    $offset: Int!\n    $timezone: String!\n  ) {\n    projectTasks(\n      projectId: $projectId\n      organisationId: $organisationId\n      teamId: $teamId\n      startDate: $startDate\n      endDate: $endDate\n      taskStatus: $taskStatus\n      search: $search\n      limit: $limit\n      offset: $offset\n      timezone: $timezone\n    ) {\n      taskId\n      taskName\n      taskStatus\n      assignedTo\n      totalWorkedTime\n      assignedToAll\n      estimatedTime\n      dueDate\n      taskLabelStatus\n      taskUser {\n        userId\n        displayName\n        profileUrl\n      }\n      priority\n      startDate\n      taskProgress\n      taskProgressUser\n      taskType\n      taskVisibility\n      description\n      taskDueStatus\n      taskCreatedBy\n    }\n  }\n"])));
export var ADD_TASK_DETAILS_MUTATION = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation AddTaskDetails(\n    $taskName: [String!]!\n    $projectId: String!\n    $organisationId: String!\n    $taskTime: Int\n    $userId: [String]!\n    $dueDate: String\n    $timeZone: String!\n    $priority: String\n    $startDate: String\n    $taskDescription: String\n  ) {\n    addTaskDetails(\n      taskName: $taskName\n      projectId: $projectId\n      organisationId: $organisationId\n      taskTime: $taskTime\n      userId: $userId\n      dueDate: $dueDate\n      timeZone: $timeZone\n      priority: $priority\n      startDate: $startDate\n      taskDescription: $taskDescription\n    )\n  }\n"])));
export var ADD_USER_TASK_DETAILS_MUTATION = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation AddUserTaskDetails($projectId: String!, $organisationId: String!, $taskId: [String!]!, $userId: [String]!) {\n    addUserTaskDetails(projectId: $projectId, organisationId: $organisationId, taskId: $taskId, userId: $userId)\n  }\n"])));
export var EDIT_TASK_DETAILS_MUTATION = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation EditTaskDetails(\n    $taskName: String!\n    $taskId: String!\n    $projectId: String!\n    $organisationId: String!\n    $stage: String!\n    $users: [String!]!\n    $taskVisibility: String\n    $taskDescription: String\n  ) {\n    editTaskDetails(\n      taskName: $taskName\n      taskId: $taskId\n      projectId: $projectId\n      organisationId: $organisationId\n      stage: $stage\n      users: $users\n      taskVisibility: $taskVisibility\n      taskDescription: $taskDescription\n    ) {\n      taskName\n      taskId\n      workedSec\n      stage\n      userList {\n        userId\n        displayName\n      }\n    }\n  }\n"])));
export var PROJECT_USERS_QUERY = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query UsersByProject($projectId: String!) {\n    usersByProject(projectId: $projectId) {\n      displayName\n      userId\n      profileUrl\n    }\n  }\n"])));
export var CHANGE_TASK_STAGE_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  mutation ChangeTaskStage($projectId: String!, $organisationId: String!, $stage: String!, $taskId: String!) {\n    changeTaskStage(projectId: $projectId, organisationId: $organisationId, stage: $stage, taskId: $taskId)\n  }\n"])));
export var DELETE_TASK_MUTATION = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation deleteTask($projectId: String!, $organisationId: String!, $taskId: String!) {\n    deleteTask(projectId: $projectId, organisationId: $organisationId, taskId: $taskId)\n  }\n"])));
export var UPDATE_TASK_STATUS_MUTATION = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation updateTaskStatus($projectId: String!, $taskId: String!, $taskStatus: String!) {\n    updateTaskStatus(projectId: $projectId, taskId: $taskId, taskStatus: $taskStatus)\n  }\n"])));
export var PORJECT_TASK_WORKED_HISTORY_QUERY = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  query ProjectTaskWorkedHistory(\n    $organisationId: String!\n    $projectId: String!\n    $teamId: String!\n    $startDate: Float!\n    $endDate: Float!\n    $limit: Int!\n    $offset: Int!\n    $timezone: String!\n  ) {\n    projectTaskWorkedHistory(\n      organisationId: $organisationId\n      projectId: $projectId\n      teamId: $teamId\n      startDate: $startDate\n      endDate: $endDate\n      limit: $limit\n      offset: $offset\n      timezone: $timezone\n    ) {\n      startTime\n      endTime\n      totalTimeInSec\n      taskDescription\n      taskId\n      projectId\n      taskName\n      projectName\n      taskDate\n      displayName\n      profileUrl\n      userId\n    }\n  }\n"])));
export var PROJECT_EMPLOYEE_WORKED_HOUR_QUERY = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  query projectEmployeesWorkedHours($projectId: String!, $organisationId: String!) {\n    projectEmployeesWorkedHours(projectId: $projectId, organisationId: $organisationId) {\n      userId\n      displayName\n      profileUrl\n      workedHours\n      taskCount\n      uniqueUrl\n      billableRate\n      userLabourCost\n      profit\n    }\n  }\n"])));
export var TASK_DETAILS_QUERY = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  query taskDetails(\n    $organisationId: String!\n    $taskId: String!\n    $projectId: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int!\n    $role: String!\n    $userUrl: String!\n  ) {\n    taskDetails(\n      organisationId: $organisationId\n      taskId: $taskId\n      projectId: $projectId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      role: $role\n      userUrl: $userUrl\n    ) {\n      taskData {\n        taskStatus\n        estimatedTime\n        dueDate\n        taskCreatedAt\n        priority\n        description\n      }\n      taskSummary {\n        totalTimeOnTask\n        totalTimeOnFilterTask\n        createdBy\n      }\n      taskUserSummaryDetails {\n        userId\n        totalTime\n        displayName\n        emailId\n        profileUrl\n      }\n      totalUserInTask\n    }\n  }\n"])));
export var TASK_EMPLOYEE_TIMELINE_QUERY = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  query taskEmployeeTimeline(\n    $organisationId: String!\n    $taskId: String!\n    $projectId: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int!\n    $role: String!\n    $userId: String!\n  ) {\n    taskEmployeeTimeline(\n      organisationId: $organisationId\n      taskId: $taskId\n      projectId: $projectId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      role: $role\n      userId: $userId\n    ) {\n      userId\n      employeeName\n      profileUrl\n      totalTime\n      createdAt\n      startTime\n      endTime\n      taskDescription\n    }\n  }\n"])));
export var TASK_ACTIVITY_SUMMARY_QUERY = gql(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  query taskActivitySummary(\n    $organisationId: String!\n    $taskId: String!\n    $projectId: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int!\n    $offset: Int!\n  ) {\n    taskActivitySummary(\n      organisationId: $organisationId\n      taskId: $taskId\n      projectId: $projectId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n    ) {\n      createdAt\n      activityData\n      activityType\n      profileUrl\n      displayName\n    }\n  }\n"])));
export var PROJECT_TASKS_EXPORT_QUERY = gql(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  query ProjectTasksExport($projectId: String!, $organisationId: String!, $teamId: String!, $timezone: String!) {\n    projectTasksExport(projectId: $projectId, organisationId: $organisationId, teamId: $teamId, timezone: $timezone) {\n      taskId\n      taskName\n      taskStatus\n      assignedTo\n      totalWorkedTime\n      assignedToAll\n      estimatedTime\n      dueDate\n      taskLabelStatus\n\n      priority\n      startDate\n      taskProgress\n      taskProgressUser\n      taskType\n      taskVisibility\n    }\n  }\n"])));
export var QUICK_EDIT_TASK_STATUS_MUTATION = gql(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  mutation quickEditTaskStatus(\n    $projectId: String!\n    $organisationId: String!\n    $taskId: [String]!\n    $status: String!\n    $timezone: String!\n  ) {\n    quickEditTaskStatus(\n      projectId: $projectId\n      organisationId: $organisationId\n      taskId: $taskId\n      status: $status\n      timezone: $timezone\n    )\n  }\n"])));
export var QUICK_EDIT_TASK_ESTIMATION_MUTATION = gql(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  mutation quickEditTaskEstimationTime(\n    $projectId: String!\n    $organisationId: String!\n    $taskId: [String]!\n    $taskTime: Int\n    $timezone: String!\n  ) {\n    quickEditTaskEstimationTime(\n      projectId: $projectId\n      organisationId: $organisationId\n      taskId: $taskId\n      taskTime: $taskTime\n      timezone: $timezone\n    )\n  }\n"])));
export var QUICK_EDIT_TASK_DUE_DATE_MUTATION = gql(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  mutation quickEditTaskDueDate(\n    $projectId: String!\n    $organisationId: String!\n    $taskId: [String]!\n    $dueDate: String!\n    $timezone: String!\n  ) {\n    quickEditTaskDueDate(\n      projectId: $projectId\n      organisationId: $organisationId\n      taskId: $taskId\n      dueDate: $dueDate\n      timezone: $timezone\n    )\n  }\n"])));
export var QUICK_EDIT_TASK_PRIORITY_MUTATION = gql(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  mutation quickEditTaskPriority(\n    $projectId: String!\n    $organisationId: String!\n    $taskId: [String]!\n    $timezone: String!\n    $priority: String!\n  ) {\n    quickEditTaskPriority(\n      projectId: $projectId\n      organisationId: $organisationId\n      taskId: $taskId\n      timezone: $timezone\n      priority: $priority\n    )\n  }\n"])));
export var QUICK_EDIT_TASK_MEMBER_MUTATION = gql(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  mutation quickEditTaskMembers(\n    $projectId: String!\n    $organisationId: String!\n    $taskId: [String]!\n    $userId: [String]!\n    $timezone: String!\n    $taskVisibility: String\n  ) {\n    quickEditTaskMembers(\n      projectId: $projectId\n      organisationId: $organisationId\n      taskId: $taskId\n      userId: $userId\n      timezone: $timezone\n      taskVisibility: $taskVisibility\n    )\n  }\n"])));
export var QUICK_DELETE_TASK_MUTATION = gql(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  mutation quickDeleteTask($projectId: String!, $organisationId: String!, $taskId: [String]!) {\n    quickDeleteTask(projectId: $projectId, organisationId: $organisationId, taskId: $taskId)\n  }\n"])));