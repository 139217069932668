var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19;
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
import { gql } from "@apollo/client";
export var ADD_MANUAL_MUTATION = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation addManualDetails(\n    $projectId: String!\n    $taskId: String\n    $taskDescription: String!\n    $startTime: String!\n    $endTime: String!\n    $taskDate: String!\n    $organisationId: String!\n    $teamId: [String]!\n    $timeZone: String!\n  ) {\n    addManualDetails(\n      projectId: $projectId\n      taskId: $taskId\n      taskDescription: $taskDescription\n      startTime: $startTime\n      endTime: $endTime\n      taskDate: $taskDate\n      organisationId: $organisationId\n      teamId: $teamId\n      timeZone: $timeZone\n    )\n  }\n"])));
export var ADD_TIMER_MUTATION = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation addTimerDetails(\n    $projectId: String!\n    $taskId: String\n    $taskDescription: String\n    $organisationId: String!\n    $teamId: [String]!\n    $timeZone: String!\n  ) {\n    addTimerDetails(\n      projectId: $projectId\n      taskId: $taskId\n      taskDescription: $taskDescription\n      organisationId: $organisationId\n      teamId: $teamId\n      timeZone: $timeZone\n    )\n  }\n"])));
export var PROJECTS_LISTING_QUERY = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query projectsListing($organisationId: String!, $teamId: String!, $from: String, $role: String!, $page: String) {\n    projectsListing(organisationId: $organisationId, teamId: $teamId, from: $from, role: $role, page: $page) {\n      projects {\n        projectId\n        projectName\n        tasks {\n          taskId\n          taskName\n        }\n      }\n    }\n  }\n"])));
export var PROJECTS_LISTING_FOR_FILTER_QUERY = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query projectsListingForFilter($organisationId: String!, $teamId: String!, $from: String, $role: String!) {\n    projectsListingForFilter(organisationId: $organisationId, teamId: $teamId, from: $from, role: $role) {\n      projects {\n        projectId\n        projectName\n        tasks {\n          taskId\n          taskName\n        }\n      }\n    }\n  }\n"])));
export var GET_TASK_TRACKER_QUERY = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query getTaskTrackerDetails(\n    $organisationId: String!\n    $teamId: String\n    $userId: [String]\n    $projectId: [String]\n    $taskId: [String]\n    $limit: Int!\n    $offset: Int!\n    $freePlanStartDate: String\n    $freePlanEndDate: String\n    $role: String!\n  ) {\n    getTaskTrackerDetails(\n      organisationId: $organisationId\n      teamId: $teamId\n      userId: $userId\n      projectId: $projectId\n      taskId: $taskId\n      limit: $limit\n      offset: $offset\n      freePlanStartDate: $freePlanStartDate\n      freePlanEndDate: $freePlanEndDate\n      role: $role\n    ) {\n      taskTrackerDatas {\n        startTime\n        endTime\n        totalTimeInSec\n        totalTime\n        taskDescription\n        taskId\n        projectId\n        taskName\n        projectName\n        taskDate\n        taskTimeId\n        user {\n          userId\n          displayName\n          uniqueUrl\n        }\n        priority\n        startDate\n        taskStatus\n        taskVisibility\n        estimatedTime\n        dueDate\n        taskLabelStatus\n        trackStatus\n      }\n      endNowButton {\n        startTime\n        endTime\n        totalTimeInSec\n        taskDescription\n        taskId\n        projectId\n        taskName\n        projectName\n        taskDate\n        taskTimeId\n        # priority\n        # startDate\n      }\n    }\n  }\n"])));
export var GET_TASK_TRACKER_EXPORT_QUERY = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query getTaskTrackerDetailsExport(\n    $organisationId: String!\n    $teamId: String\n    $userId: [String]\n    $projectId: [String]\n    $taskId: [String]\n  ) {\n    getTaskTrackerDetailsExport(\n      organisationId: $organisationId\n      teamId: $teamId\n      userId: $userId\n      projectId: $projectId\n      taskId: $taskId\n    ) {\n      taskTrackerDatas {\n        startTime\n        endTime\n        totalTimeInSec\n        totalTime\n        taskDescription\n        taskId\n        projectId\n        taskName\n        projectName\n        taskDate\n        taskTimeId\n        user {\n          userId\n          displayName\n          uniqueUrl\n        }\n        priority\n        startDate\n        taskStatus\n        taskVisibility\n        estimatedTime\n        dueDate\n        taskLabelStatus\n        trackStatus\n      }\n    }\n  }\n"])));
export var USER_LISTING_QUERY = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query getProjectUser($organisationId: String!, $teamId: String!, $role: String) {\n    getProjectUser(organisationId: $organisationId, teamId: $teamId, role: $role) {\n      userId\n      displayName\n    }\n  }\n"])));
export var EDIT_TASK_TRACKER_MUTATION = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation editTaskTrackerDetails(\n    $organisationId: String!\n    $taskDate: String!\n    $taskTimeId: String!\n    $timeZone: String!\n    $oldProjectId: String\n    $oldTaskId: String\n    $projectDetails: [AddProjectDetails]\n  ) {\n    editTaskTrackerDetails(\n      organisationId: $organisationId\n      taskDate: $taskDate\n      taskTimeId: $taskTimeId\n      timeZone: $timeZone\n      oldProjectId: $oldProjectId\n      oldTaskId: $oldTaskId\n      projectDetails: $projectDetails\n    ) {\n      taskTrackerDatas {\n        startTime\n        endTime\n        totalTimeInSec\n        totalTime\n        taskDescription\n        taskId\n        projectId\n        taskName\n        projectName\n        taskDate\n        taskTimeId\n        user {\n          userId\n          displayName\n          uniqueUrl\n        }\n        priority\n        startDate\n      }\n    }\n  }\n"])));
export var GET_MANUAL_LISTING_QUERY = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query getManualTaskDetails(\n    $organisationId: String!\n    $userId: String\n    $startDate: Float\n    $endDate: Float\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $projectId: String\n    $from: String\n  ) {\n    getManualTaskDetails(\n      organisationId: $organisationId\n      userId: $userId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      projectId: $projectId\n      from: $from\n    ) {\n      manualTaskId\n      startTime\n      endTime\n      taskDescription\n      reviewStatus\n      reviewedName\n      projectId\n      projectName\n      totalHours\n      taskDate\n      totalTimeInSec\n      reviewedAt\n      createdAt\n    }\n  }\n"])));
export var ADD_MANUAL_TASK_MUTATION = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation addManualTime(\n    $taskDescription: String!\n    $projectId: String\n    $taskId: String\n    $startTime: String!\n    $endTime: String!\n    $taskDate: String!\n    $organisationId: String!\n    $teamId: String!\n    $timeZone: String!\n  ) {\n    addManualTime(\n      taskDescription: $taskDescription\n      projectId: $projectId\n      taskId: $taskId\n      startTime: $startTime\n      endTime: $endTime\n      taskDate: $taskDate\n      organisationId: $organisationId\n      teamId: $teamId\n      timeZone: $timeZone\n    )\n  }\n"])));
export var DELETE_MANUAL_TASK_MUTATION = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation deleteManualTask(\n    $manualTaskId: String!\n    $organisationId: String!\n    $projectId: String\n    $taskDate: String\n    $totalTimeInSec: Int\n  ) {\n    deleteManualTask(\n      manualTaskId: $manualTaskId\n      organisationId: $organisationId\n      projectId: $projectId\n      taskDate: $taskDate\n      totalTimeInSec: $totalTimeInSec\n    )\n  }\n"])));
export var END_TIMER_MUTATION = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  mutation endTimerDetails(\n    $projectId: String!\n    $taskId: String\n    $taskDescription: String\n    $organisationId: String!\n    $startTime: Float!\n    $taskTimeId: String!\n  ) {\n    endTimerDetails(\n      projectId: $projectId\n      taskId: $taskId\n      taskDescription: $taskDescription\n      organisationId: $organisationId\n      startTime: $startTime\n      taskTimeId: $taskTimeId\n    )\n  }\n"])));
export var DELETE_TASK_TRACKER_MUTATION = gql(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  mutation deleteTaskTracker(\n    $organisationId: String!\n    $taskTimeId: String!\n    $projectId: String!\n    $taskId: String\n    $taskDate: String\n    $totalTimeInSec: Int\n  ) {\n    deleteTaskTracker(\n      organisationId: $organisationId\n      taskTimeId: $taskTimeId\n      projectId: $projectId\n      taskId: $taskId\n      taskDate: $taskDate\n      totalTimeInSec: $totalTimeInSec\n    )\n  }\n"])));
export var TASK_USERS_QUERY = gql(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  query getTaskUserDetails($organisationId: String!, $projectId: String!) {\n    getTaskUserDetails(organisationId: $organisationId, projectId: $projectId) {\n      userId\n      displayName\n      profileUrl\n    }\n  }\n"])));
export var GET_FILTER_TASK_TRACKER_DETAILS_QUERY = gql(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  query getFilterTaskTrackerDetails(\n    $organisationId: String!\n    $userId: [String]\n    $projectId: [String]\n    $taskId: [String]\n    $limit: Int!\n    $offset: Int!\n    $filterType: String!\n    $timezone: String!\n    $role: String\n  ) {\n    getFilterTaskTrackerDetails(\n      organisationId: $organisationId\n      userId: $userId\n      projectId: $projectId\n      taskId: $taskId\n      limit: $limit\n      offset: $offset\n      filterType: $filterType\n      timezone: $timezone\n      role: $role\n    ) {\n      totalTimeInSec\n      totalTime\n      taskId\n      projectId\n      taskName\n      projectName\n      dueDate\n      assignedTo\n      taskCompletedBy\n      taskCompletedAt\n      assignedToAll\n      assignedDate\n\n      taskUser {\n        userId\n        displayName\n        profileUrl\n      }\n      estimatedTime\n      taskStatus\n      taskProgress\n      taskProgressUser\n      priority\n      startDate\n      estimatedTimeInHours\n      taskType\n      taskVisibility\n      taskCreatedByName\n      taskLabelStatus\n      taskCreatedBy\n    }\n  }\n"])));
export var TASK_COUNT_QUERY = gql(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  query taskCount($organisationId: String!, $userId: [String], $projectId: [String], $taskId: [String], $role: String) {\n    taskCount(organisationId: $organisationId, userId: $userId, projectId: $projectId, taskId: $taskId, role: $role) {\n      timelineCount\n      inProgressCount\n      upcomingTaskCount\n      onDueTaskCount\n      completedTaskCount\n      allTaskCount\n      manualTaskCount\n      overWorkedTaskCount\n      holdTaskCount\n    }\n  }\n"])));
export var TASK_DETAILS_FOR_USER_QUERY = gql(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  query taskDetailsForUser(\n    $organisationId: String!\n    $projectId: String!\n    $taskId: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $userIds: String\n    $role: String\n    $url: String\n  ) {\n    taskDetailsForUser(\n      organisationId: $organisationId\n      projectId: $projectId\n      taskId: $taskId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      userIds: $userIds\n      role: $role\n      url: $url\n    ) {\n      taskStatus\n      estimatedTime\n      dueDate\n      taskCreatedAt\n      priority\n      totalTimeOnTask\n      totalTimeOnFilterTask\n      createdBy\n      holdBy\n      holdAt\n      completedBy\n      completedAt\n    }\n  }\n"])));
export var TEAM_ACTIVITIES_PROJECT_HEADER_QUERY = gql(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  query teamActivitiesProjectHeader(\n    $organisationId: String!\n    $teamId: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n  ) {\n    teamActivitiesProjectHeader(\n      organisationId: $organisationId\n      teamId: $teamId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n    ) {\n      totalTime\n      topProject\n      topPerformer\n      projectWorkDetails {\n        day\n        projects {\n          projectId\n          projectName\n          workedSec\n        }\n      }\n      workDetails {\n        projectId\n        projectName\n        workedSec\n      }\n    }\n  }\n"])));
export var TEAM_ACTIVITIES_PROJECT_USER_QUERY = gql(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n  query teamActivitiesProjectUsers(\n    $organisationId: String!\n    $teamId: String!\n    $startDate: Float!\n    $endDate: Float!\n    $timeZone: String!\n    $limit: Int\n    $offset: Int\n    $userRole: String!\n  ) {\n    teamActivitiesProjectUsers(\n      organisationId: $organisationId\n      teamId: $teamId\n      startDate: $startDate\n      endDate: $endDate\n      timeZone: $timeZone\n      limit: $limit\n      offset: $offset\n      userRole: $userRole\n    ) {\n      userId\n      displayName\n      profileUrl\n      totalWorkHours\n      totalWorkSec\n      expectedWorkedHours\n      projects {\n        projectId\n        projectName\n        workedSec\n        workedHours\n        colorCode\n      }\n    }\n  }\n"])));